import React, { Component } from 'react';
// import './css/App.scss';
import './App.css';
import chart from './img/chart.svg'
import ii_logo from './img/ii_logo.svg'
 
// COMPANY component
class Company extends React.Component {
    constructor(props) {
        super(props);
      }
  
    render() {      
        return (
          <li  className={this.props.className} data-code={this.props.code} onClick={this.props.onClick} >
            {this.props.name}
          </li>
        );
    }
}

// main APP component
class App extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        liveUrl:'',
        comp_name:'',
        code:'',
        value:'',
        gbp:this.getGBP(),
        eur:this.getGBP(),
        cur_val_uk:'',
        cur_val_us:'',
        invest:100,
        printInvest:0,
        profit:'',
        currencyToggled:false,
        companyToggled: false,
        currency:'uk',
        ipo_date:'',
        best_date:'',
        db: {
            'DIS': {
              "sedol":'2270726',
              "link":"https://finance.yahoo.com/quote/DIS/history",
              "ipo-date": "1/2/62",
              "init-val-us": 0.09,
              "init-val-uk": 0.03,
              "best-day": "10/24/62",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'NKE': {
              "sedol":'2640147',
              "link":"https://finance.yahoo.com/quote/NKE/history",
              "ipo-date": "2/12/80",
              "init-val-us": 0.18,
              "init-val-uk": 0.08,
              "best-day": "11/1/84",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'SNE': {
              "sedol":'2821481',
              "link":"https://finance.yahoo.com/quote/SNE/history",
              "ipo-date": "7/26/74",
              "init-val-us": 2.36364,
              "init-val-uk": 0.99,
              "best-day": "10/22/74",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'MSFT': {
              "sedol":'2588173',
              "link":"https://finance.yahoo.com/quote/MSFT/history",
              "ipo-date": "3/13/86",
              "init-val-us": 0.088542,
              "init-val-uk": 0.06,
              "best-day": "3/13/86",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'INTC': {
              "sedol":'2463247',
              "link":"https://finance.yahoo.com/quote/INTC/history",
              "ipo-date": "3/17/80",
              "init-val-us": 0.325521,
              "init-val-uk": 0.15,
              "best-day": "1/13/82",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'AAPL': {
              "sedol":'2270726',
              "link":"https://finance.yahoo.com/quote/AAPL/history",
              "ipo-date": "12/12/80",
              "init-val-us": 0.513393,
              "init-val-uk": 0.22,
              "best-day": "7/8/82",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'BA': {
              "sedol":'2108601',
              "link":"https://finance.yahoo.com/quote/BA/history",
              "ipo-date": "1/2/62",
              "init-val-us": 0.837449,
              "init-val-uk": 0.30,
              "best-day": "1/9/74",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'NFLX': {
              "sedol":'2857817',
              "link":"https://finance.yahoo.com/quote/NFLX/history",
              "ipo-date": "5/23/02",
              "init-val-us": 1.156429,
              "init-val-uk": 0.80,
              "best-day": "10/10/02",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'NVDA': {
              "sedol":'2379504',
              "link":"https://finance.yahoo.com/quote/NVDA/history",
              "ipo-date": "1/22/99",
              "init-val-us": 1.75,
              "init-val-uk": 1.06,
              "best-day": "4/27/99",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'V': {
              "sedol":'B2PZN04',
              "link":"https://finance.yahoo.com/quote/V/history",
              "ipo-date": "3/19/08",
              "init-val-us": 14.875,
              "init-val-uk": 7.45,
              "best-day": "1/23/09",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'SPOT': {
              "sedol":'BFZ1K46',
              "link":"https://finance.yahoo.com/quote/SPOT/history",
              "ipo-date": "4/23/18",
              "init-val-us": 165.9,
              "init-val-uk": 124.63,
              "best-day": "11/20/18",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'SHOP': {
              "sedol":'BXDZ9Z0',
              "link":"https://finance.yahoo.com/quote/SHOP/history",
              "ipo-date": "5/20/15",
              "init-val-us": 17,
              "init-val-uk": 10.94,
              "best-day": "5/20/15",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'TSLA': {
              "sedol":'B616C79',
              "link":"https://finance.yahoo.com/quote/TSLA/history",
              "ipo-date": "6/29/10",
              "init-val-us": 19,
              "init-val-uk": 12.62,
              "best-day": "7/8/10",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'INTU': {
              "sedol":'2459020',
              "link":"https://finance.yahoo.com/quote/INTU/history",
              "ipo-date": "3/12/93",
              "init-val-us": 2.291667,
              "init-val-uk": 1.60,
              "best-day": "4/20/93",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'FOX': {
              "sedol":'BBM4S80',
              "link":"https://finance.yahoo.com/quote/FOX/history",
              "ipo-date": "5/20/86",
              "init-val-us": 2.337309,
              "init-val-uk": 1.53,
              "best-day": "1/16/91",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'AMZN': {
              "sedol":'2000019',
              "link":"https://finance.yahoo.com/quote/AMZN/history",
              "ipo-date": "5/15/97",
              "init-val-us": 2.4375,
              "init-val-uk": 1.49,
              "best-day": "5/20/97",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'SNAP': {
              "sedol":'BD8DJ71',
              "link":"https://finance.yahoo.com/quote/SNAP/history",
              "ipo-date": "3/2/17",
              "init-val-us": 24,
              "init-val-uk": 19.53,
              "best-day": "12/11/18",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'AMD': {
              "sedol":'2007849',
              "link":"https://finance.yahoo.com/quote/AMD/history",
              "ipo-date": "3/17/80",
              "init-val-us": 3.125,
              "init-val-uk": 1.44,
              "best-day": "7/28/15",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'CRM': {
              "sedol":'2310525',
              "link":"https://finance.yahoo.com/quote/CRM/history",
              "ipo-date": "6/23/04",
              "init-val-us": 3.75,
              "init-val-uk": 2.06,
              "best-day": "8/16/04",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'PYPL': {
              "sedol":'BYW36M8',
              "link":"https://finance.yahoo.com/quote/PYPL/history",
              "ipo-date": "7/6/15",
              "init-val-us": 38,
              "init-val-uk": 24.44,
              "best-day": "10/1/15",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'MA': {
              "sedol":'B121557',
              "link":"https://finance.yahoo.com/quote/MA/history",
              "ipo-date": "5/25/06",
              "init-val-us": 4.03,
              "init-val-uk": 2.16,
              "best-day": "5/25/06",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'FB': {
              "sedol":'B7TL820',
              "link":"https://finance.yahoo.com/quote/FB/history",
              "ipo-date": "5/18/12",
              "init-val-us": 42.05,
              "init-val-uk": 26.58,
              "best-day": "9/4/12",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'TWTR': {
              "sedol":'BFLR866',
              "link":"https://finance.yahoo.com/quote/TWTR/history",
              "ipo-date": "11/7/13",
              "init-val-us": 45.1,
              "init-val-uk": 28.08,
              "best-day": "5/4/16",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'GOOG': {
              "sedol":'BYVY8G0',
              "link":"https://finance.yahoo.com/quote/GOOG/history",
              "ipo-date": "8/19/04",
              "init-val-us": 49.676899,
              "init-val-uk": 27.20,
              "best-day": "9/2/04",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'UA': {
              "sedol":'B0PZN11',
              "link":"https://finance.yahoo.com/quote/UA/history",
              "ipo-date": "7/1/15",
              "init-val-us": 84.26,
              "init-val-uk": 53.82,
              "best-day": "11/6/17",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'BABA': {
              "sedol":'BP41ZD1',
              "link":"https://finance.yahoo.com/quote/BABA/history",
              "ipo-date": "9/19/14",
              "init-val-us": 92.7,
              "init-val-uk": 56.73,
              "best-day": "9/29/15",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'ADS.DE': {
              "sedol":'4031976',
              "link":"https://finance.yahoo.com/quote/ADS.DE/history",
              "ipo-date": "6/24/98",
              "init-val-us": 44.51,
              "init-val-uk": 26.64,
              "best-day": "9/21/01",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'ASC.L': {
              "sedol":'3092725',
              "link":"https://finance.yahoo.com/quote/ASC.L/history",
              "ipo-date": "10/3/01",
              "init-val-us": 34.65,
              "init-val-uk": 23.50,
              "best-day": "8/8/03",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'BOO.L': {
              "sedol":'BG6L729',
              "link":"https://finance.yahoo.com/quote/BOO.L/history",
              "ipo-date": "3/14/14",
              "init-val-us": 141.06,
              "init-val-uk": 85.00,
              "best-day": "1/8/15",
              'cur-val-uk':'',
              'cur-val-us':''
            },
            'FEVR.L': {
              "sedol":'BRJ9BJ2',
              "link":"https://finance.yahoo.com/quote/FEVR.L/history",
              "ipo-date": "11/7/14",
              "init-val-us": 268.93,
              "init-val-uk": 170.00,
              "best-day": "11/14/14",
              'cur-val-uk':'',
              'cur-val-us':''
            }
        }
      }
      this.changeInvest = this.changeInvest.bind(this)
      this.compClick = this.compClick.bind(this)
    }

    getGBP(){
      fetch("https://api.exchangeratesapi.io/latest?base=USD")
        .then(res => res.json())
        .then(
        (result) => {
          this.setState({
            gbp: result.rates.GBP,
            eur: result.rates.EUR
          })
        }, 
        (error) => {
          this.setState({
            gbp: 0.774,
            eur: 0.888
          })
        })        
    }

    compClick(c,n) {
       this.setState({
        code: c,
        comp_name: n,
        // companyToggled: !this.state.companyToggled,
        value: this.state.db[c]['init-val-'+this.state.currency],
        ipo_date: this.state.db[c]['ipo-date'],
        best_date: this.state.db[c]['best-day'],
       }, function(){
        var temp_uk, temp_us
        if (this.state.db[c]['cur-val-uk']){
          temp_uk = this.state.db[c]['cur-val-uk']
          temp_us = this.state.db[c]['cur-val-us']
        }else {
          temp_uk =''
          temp_us =''
        }
        this.setState({
             cur_val_uk: temp_uk,
             cur_val_us: temp_us,
             profit:''
           })
       })    
    }

    btnCalculate() {
      if (this.state.code) {
        if (this.state.db[this.state.code]['cur-val-us']) {
          this.calculate()
        } else {
          fetch('https://api-prod.ii.co.uk/api/1/instruments/COMPANY/price/'+this.state.db[this.state.code].sedol)
            .then(res => res.json())
            .then(
              (result) => {
                var temp_price = result.lastTradePrice.price
                var temp_cur = result.currency.code

                if (temp_cur === 'GBX') {
                  // IN POUNDS
                  this.setState({
                    cur_val_us: Math.round(temp_price / this.state.gbp * 1e2) / 1e2,
                    cur_val_uk: Math.round(temp_price * 1e2) / 1e2
                  })
                } else if (temp_cur === 'EUR') {
                  // IN EUROS
                  this.setState({
                    cur_val_us: Math.round(temp_price / this.state.eur * 1e2) / 1e2,
                    cur_val_uk: Math.round(temp_price / this.state.eur * this.state.gbp * 1e2) / 1e2
                  })
                } else {
                  // IN DOLLARS
                  this.setState({
                    cur_val_us: Math.round(temp_price * 1e2) / 1e2,
                    cur_val_uk: Math.round(temp_price * this.state.gbp * 1e2) / 1e2
                  })
                }

                
      

                let stateCopy = Object.assign({}, this.state)
                stateCopy.db[this.state.code]['cur-val-us'] = this.state.cur_val_us
                stateCopy.db[this.state.code]['cur-val-uk'] = this.state.cur_val_uk
                this.setState(stateCopy)
                this.calculate()

              },
              (error) => {
                console.log('error')
              }
            )
        }
      }

    }

    calculate(){
      var temp_val = 0
      this.state.currency === 'uk' ? temp_val = this.state.cur_val_uk : temp_val = this.state.cur_val_us
     
      var temp_prof = (this.state.invest /  this.state.value) * temp_val - this.state.invest
      this.setState({
        profit : Math.round(temp_prof * 1e2) / 1e2,
        printInvest: this.state.invest
      })

      console.log(this.state)
    }

    toggleClick() {

      if (this.state.currencyToggled === false){
        
        this.setState({currency:'us', currencyToggled: !this.state.currencyToggled})
        
        if (this.state.code) {
          this.setState({value: this.state.db[this.state.code]['init-val-us']}, function(){
            if (this.state.profit) {this.calculate()}
          })
          
        }


      } else {
        
        this.setState({currency:'uk', currencyToggled: !this.state.currencyToggled})
        
        if (this.state.code) {
          this.setState({value: this.state.db[this.state.code]['init-val-uk']}, function(){
            if (this.state.profit) {this.calculate()}
          })
          
        }

      }      
    }

    changeInvest(event){
      this.setState({invest: event.target.value});
    }

    renderCompany(c,n){
        return <Company className={ this.state.companyToggled ? "active" : "" } code={c} name={n} onClick={() => this.compClick(c,n)}/>
    }

    render() {

        return (
         <div className="ip_app">
          <div className="ip_wrapper">
            <section className="header">
              <div className="flex">
                <div> 
                  <h1>Best Missed Investments: <span>How much could you have made?</span></h1>
                  <p>The power of hindsight is a wonderful thing, and nothing hits quite as hard as the money that you could have made. Our new interactive calculator uses real-time stock market <a href="https://protect-eu.mimecast.com/s/Jy8JCXoG5C4gqqMYt6OBWG?domain=emea01.safelinks.protection.outlook.com">share</a> prices and historical IPO data to reveal what you could have earned in today’s money. Find out how rich you’d be if you’d invested in 30 of the most successful, iconic companies when they first floated.</p>
                  <p>Simply click on one of the 30 companies below, input your investment figure to reveal what you could have made.</p>
            
                  <p>Simply click on one of the 30 companies below, input your investment figure to reveal what you could have made.</p>
            
                </div>
                <div>
                  <img src={chart} alt="" />
                </div>
              </div>
            </section>
            <section className="main">
              <p className="disc"><b>Disclaimer:</b> Past performance is not a reliable indicator to future performance.</p>
              
              <div className="flex">
                <div className="company_wrap">
                  <ul className="comps">
                    {this.renderCompany("DIS","The Walt Disney Company")}
                    {this.renderCompany("NKE","Nike")}
                    {this.renderCompany("SNE","Sony")}
                    {this.renderCompany("MSFT","Microsoft")}
                    {this.renderCompany("INTC","Intel")}
                    {this.renderCompany("AAPL","Apple")}
                    {this.renderCompany("BA","Boeing")}
                    {this.renderCompany("NFLX","Netflix")}
                    {this.renderCompany("NVDA","Nvidia")}
                    {this.renderCompany("V","Visa")}
                    {this.renderCompany("SPOT","Spotify")}
                    {this.renderCompany("SHOP","Shopify")}
                    {this.renderCompany("TSLA","Tesla")}
                    {this.renderCompany("INTU","Intuit")}
                    {this.renderCompany("FOX","Twenty-First Century Fox, Inc.")}
                    {this.renderCompany("AMZN","Amazon")}
                    {this.renderCompany("SNAP","Snap Inc. (Snapchat)")}
                    {this.renderCompany("AMD","AMD")}
                    {this.renderCompany("CRM","Salesforce")}
                    {this.renderCompany("PYPL","PayPal")}
                    {this.renderCompany("MA","Mastercard")}
                    {this.renderCompany("FB","Facebook")}
                    {this.renderCompany("TWTR","Twitter")}
                    {this.renderCompany("GOOG","Alphabet (Google)")}
                    {this.renderCompany("UA","Under Armour")}
                    {this.renderCompany("BABA","Alibaba")}
                    {this.renderCompany("ADS.DE","Adidas")}
                    {this.renderCompany("ASC.L","ASOS")}
                    {this.renderCompany("BOO.L","boohoo")}
                    {this.renderCompany("FEVR.L","Fevertree")}
                  </ul>
                </div>
                <div className="calc_wrap" id='results'>
                  <div className="inner">
                    <div className="currency">
                      <div><p>Select a company and enter your investment to see the results</p></div>
                      <div className="toggle_wrap">
                        <div>£</div>
                        <div className="toggle" data-name={this.state.currency} onClick={() => this.toggleClick()}><div className="circle"></div></div>
                        <div>$</div>
                      </div>
                    </div>
                    <h2>{this.state.comp_name}</h2>
                    <div className="input_wrap">
                      <p>
                        <label htmlFor="trade_id">Trading symbol</label><input type="text" value={this.state.code} disabled id="trade_id" name="trade_id" />
                      </p>
                      <p>
                        <label htmlFor="ipo_date">Initial IPO date</label><input type="text" value={this.state.ipo_date} disabled id="ipo_date" name="ipo_date" />
                      </p>
                      <p>
                        <label htmlFor="float_val">Initial float value</label><input type="text" value={this.state.currency === 'uk' ? '£'  + this.state.value: '$' + this.state.value} disabled id="float_val" name="float_val" />
                      </p>
                      <p>
                        <label htmlFor="best_day">Best day to have bought shares</label><input type="text" value={this.state.best_date} disabled id="best_day" name="best_day" />
                      </p>
                    </div>
                    <div className="output_wrap">
                      <div className="col_wrap">
                        <div className="col">
                          <div className="row">
                            <p>
                              <label htmlFor="investment">Enter investment</label>
                            </p>
                            <p className="row flex">
                              {this.state.currency === 'uk' ? '£' : '$'}<input type="text" id="investment" name="investment" value={this.state.invest} onChange={this.changeInvest} />
                            </p>
                          </div>
                          <div className="row">
                            <p><button onClick={() => this.btnCalculate()}>Calculate</button></p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <p>
                              <label htmlFor="cur_val">Current share value</label>
                            </p>
                            <p>
                              <input type="text" id="cur_val" value={this.state.currency === 'uk' ? '£' + this.state.cur_val_uk : '$' + this.state.cur_val_us} disabled name="cur_val" />
                            </p>
                          </div>
                          <div className="row">
                            <p>
                              <label htmlFor="profit">Net profit</label>
                            </p>
                            <p>
                              <input type="text" value={this.state.currency === 'uk' ? '£' + this.state.profit : '$' + this.state.profit} id="profit" disabled name="profit" />
                            </p>
                          </div>
                        </div>
                        <p className="outro">IF YOU INVESTED <span>{this.state.currency === 'uk' ? '£' + this.state.printInvest : '$' + this.state.printInvest}</span> ON INITIAL FLOAT DATE YOU WOULD HAVE MADE <span>{this.state.currency === 'uk' ? '£' + this.state.profit : '$' + this.state.profit}</span></p>
                        <div className="links">
                          <div>
                            <a className="button" target="_blank" rel="noopener noreferrer" href={this.state.code ? this.state.db[this.state.code].link : "https://finance.yahoo.com/"}>Source</a>
                          </div>
                          <div className="share_btns">
                            <a className="button" target="_blank" rel="noopener noreferrer" href={"https://twitter.com/intent/tweet?url="+this.state.liveUrl+"&via=ii_couk&text=Find%20how%20much%20you%20could%20have%20made%20if%20you%20invested%20on%20stock%20IPO%20date"}>Share on Twitter</a>
                            <a className="button" target="_blank" rel="noopener noreferrer" href={"//www.facebook.com/sharer/sharer.php?" + this.state.liveUrl}>Share on Facebook</a>
                          </div>

                        </div>
                      </div>
                    </div>      
                  </div>
                </div>
              </div> 
            </section>
            <section className="footer">
              <div className="info">
                <div>
                  <p className="sources">Historical IPO data sourced from <a href="https://finance.yahoo.com/quote/NKE/history">Yahoo Finance</a> dating back 57 years to 1962 and updated in real-time via API from <a href="https://www.morningstar.com/">Morningstar</a> (NIKE example). Initial float value converted from dollars to pence using a historic currency converter. Real-time share value calculated based on current <a href="http://fxtop.com/en/currency-converter-past.php?A=0.18&C1=USD&C2=GBP&DD=12&MM=02&YYYY=1980&B=1&P=&I=1&btnOK=Go%21">currency</a> value multiplied by price of shares at time of IPO. </p>
                </div>
                <div>
                  <p className="outro">Investing in IPOs carries a high degree of risk. If you are unsure of the suitability of an investment please seek Financial Advice. You are not guaranteed to make a profit, the value of your investments can go down as well as up. You may not get back all the money you invest.</p>
                </div>
              </div>
              <div className="cc">
                <div>
                  <a rel="license" href="http://creativecommons.org/licenses/by-sa/3.0/"><img alt="Creative Commons License" src="https://i.creativecommons.org/l/by-sa/3.0/88x31.png" /></a><br />This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-sa/3.0/">Creative Commons Attribution-ShareAlike 3.0 Unported License</a>.
                </div>
                <div>
                  <img src={ii_logo} alt="ii.co.uk" />
                </div>
              </div>
            </section>
          </div>
         </div>
        );
    }
}

export default App;